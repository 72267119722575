<template>
  <el-row v-loading="loading" type="flex" class="reset-password" justify="center" align="middle">
    <el-col class="reset-password__container">
      <el-form
        v-if="!loading"
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
        label-width="100px"
        :show-message="true"
        class="reset-password__form"
        size="small"
      >
        <el-form-item key="title">
          <h3>Hybrid Warehouse</h3>
        </el-form-item>

        <el-form-item key="password" :label="$t('user.password.label')" prop="password">
          <el-input
            v-model="formData.password"
            type="password"
            name="password"
            autocomplete="new-password"
            show-password
          />
        </el-form-item>
        <el-form-item
          key="repeatPassword"
          :label="$t('user.repeatPassword.label')"
          prop="repeatPassword"
        >
          <el-input
            v-model="formData.repeatPassword"
            type="password"
            name="repeat-password"
            autocomplete="new-password"
            show-password
          />
        </el-form-item>
        <el-form-item key="submit">
          <el-button
            class="el-button--stretch"
            type="primary"
            :loading="submitLoading"
            :disabled="submitDisabled"
            @click="handleReset"
            >{{ $t('resetPassword') }}</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ResetPassword',

  metaInfo() {
    return {
      title: this.$i18n.t('title'),
    }
  },

  data() {
    return {
      formData: {
        password: '',
        repeatPassword: '',
      },

      formRules: {
        password: {
          required: true,
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$i18n.t('user.password.required')))
            } else if (value.toString().length < 8 || value.toString().length > 100) {
              callback(new Error(this.$i18n.t('user.password.invalidLength')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        repeatPassword: {
          required: true,
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$i18n.t('user.repeatPassword.required')))
            } else if (value !== this.formData.password) {
              callback(new Error(this.$i18n.t('user.repeatPassword.notMatch')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      },
      loading: true,
      submitLoading: false,
      submitDisabled: false,
    }
  },

  watch: {
    formData: {
      handler() {
        this.submitDisabled = false
      },
      deep: true,
    },
  },

  async created() {
    const { token } = this.$route.params
    if (!token) {
      this.$router.push('/auth')
    } else {
      await this.checkResetToken(token)
      this.loading = false
    }
  },

  methods: {
    ...mapActions('auth', ['resetPassword', 'checkResetToken']),
    async handleReset() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          await this.resetPassword({
            token: this.$route.params.token,
            password: this.formData.password,
          })
          this.submitLoading = false
        } else {
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "Reset password",
    "resetPassword": "Change password"
  },
  "ru": {
    "title": "Восстановление пароля",
    "resetPassword": "Изменить пароль"
  }
}
</i18n>

<style lang="scss">
.reset-password {
  height: 100%;
  background: $color-primary;
}
.reset-password__container {
  max-width: 432px;
}
.reset-password__form {
  background-color: #fff;
  border-radius: 4px;
  padding: 24px 32px;
}
</style>
